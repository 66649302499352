<script>
import {mapGetters} from "vuex"

export default {
    name: "ConditionalRoleVisibility",
    props: {
        visibleFor: {
            type: Array,
            required: true
        }
    },
    computed: {
        ...mapGetters("auth", ["roles"]),
        isVisible(){
            const coincidences = this.roles.filter(value => this.visibleFor.includes(value));
            return coincidences.length > 0
        }
    }
}
</script>